import moment from 'moment'

export default {
  title: '',
  searchIn: 'title',
  id: '',
  site: null,
  rubric: 0,
  type: '',
  authors: [],
  users: [],
  tags: [],
  categories: [],
  dateFrom: moment().add(-6, 'months').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
  dateTo: null,
  status: 'published',
  documentIds: [],
  originalArticle: null,
  isPrArticle: false,
  disableTransfer: false
}
